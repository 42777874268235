import { useNotify, useRefresh, useDataProvider, Button } from "react-admin";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckIcon from "@mui/icons-material/Check";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// import timezones from "../../constants/timezones";
import {
  PLATFORM,
  PLATFORM_CURRENCIES,
  AD_ACCOUNT_STATUS,
} from "../../constants";
import industries from "../../constants/industries";

export const ApproveButton = ({
  status,
  adAccountId,
  platformId,
  platformCurrency,
  commissionCategory,
  platformTimezone,
  platformCountryCode,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const approve = useMutation(
    ({
      adAccountId,
      categoryId,
      currency,
      bmID,
      timezone,
      industry,
      countryCode,
      creditAccount,
      platformUrl,
    }) =>
      dataProvider.approveAdAccount({
        adAccountId,
        categoryId,
        currency,
        countryCode,
        bmID: bmID?.id,
        industry: String(industry?.id),
        timezone: timezone.key,
        creditAccountId: creditAccount?.id,
        platformUrl,
      }),
    {
      onSuccess: () => {
        refresh();
        notify("Ad account approved", { type: "success" });
      },
      onError: (err) => {
        notify(err.error, { type: "warning" });
      },
    }
  );

  const handleConfirm = (
    categoryId,
    currency,
    bmID,
    timezone,
    industry,
    countryCode,
    creditAccount,
    platformUrl
  ) => {
    approve.mutate({
      adAccountId,
      categoryId,
      currency,
      bmID,
      timezone,
      industry,
      countryCode,
      creditAccount,
      platformUrl,
    });
  };

  return (
    <FormDialog
      loading={approve.isLoading}
      handleConfirm={handleConfirm}
      platformId={platformId}
      platformCurrency={platformCurrency}
      status={status}
      adAccountId={adAccountId}
      commissionCategory={commissionCategory}
      platformTimezone={platformTimezone}
      platformCountryCode={platformCountryCode}
    />
  );
};

export default function FormDialog({
  loading,
  handleConfirm,
  platformId,
  platformCurrency,
  status,
  adAccountId,
  commissionCategory,
  /*platformTimezone*/
  platformCountryCode = "TR",
}) {
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(commissionCategory?.id);
  const [currency, setCurrency] = useState(platformCurrency);
  const [bmIDs, setBmIDs] = useState([]);
  const [bmID, setBmID] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const [timezoneInput, setTimezoneInput] = useState("");
  const [industry, setIndustry] = useState(null);
  const [industryInput, setIndustryInput] = useState("");
  const [countries, setCountries] = useState([]);
  const [googleTimezones, setGoogleTimezones] = useState([]);
  const [creditAccounts, setCreditAccounts] = useState([]);
  const [creditAccount, setCreditAccount] = useState([]);
  const [countryCode, setCountryCode] = useState(
    platformCountryCode.toLowerCase() || null
  );
  const [platformUrl, setPlatformUrl] = useState(null);
  const dataProvider = useDataProvider();

  useEffect(() => {
    fetchCategories();
    fetchBmIDs();
    fetchCountries();
    fetchGoogleTimezones();
    fetchPlatformCreditAccounts();
  }, []);

  useEffect(() => {
    fetchAdditionalData();
  }, [bmID]);

  const fetchCategories = async () => {
    const { data: CommissionCategories } =
      await dataProvider.getCommissionCategories();
    setCategories(CommissionCategories);
  };
  const fetchBmIDs = async () => {
    const { data: bmIDs } = await dataProvider.getBmIDs(platformId);
    setBmIDs(bmIDs);
  };
  const fetchPlatformCreditAccounts = async () => {
    const { data: creditAccounts } =
      await dataProvider.getPlatformCreditAccounts(platformId);

    setCreditAccounts(creditAccounts);
  };
  const fetchCountries = async () => {
    const { data: countries } = await dataProvider.getCountries();
    setCountries(countries);
  };
  const fetchGoogleTimezones = async () => {
    const { data: googleTimezones } = await dataProvider.getTimezones();
    setGoogleTimezones(googleTimezones);
  };
  const fetchAdditionalData = async () => {
    if (platformId === PLATFORM.TIKTOK) {
      const { data: info } = await dataProvider.getList(
        "additional-informations",
        {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "id", order: "ASC" },
          filter: { ad_account_id: adAccountId },
        }
      );
      setPlatformUrl(info[0]?.platform_url);
    }
  };

  const isShowFormOfPlatform = useMemo(() => {
    return (
      status === AD_ACCOUNT_STATUS.PENDING &&
      Object.values(PLATFORM).includes(+platformId)
    );
  }, [status, platformId]);

  return (
    <div>
      <Button
        color="primary"
        label="Approve"
        startIcon={<CheckIcon />}
        onClick={() => setOpen(true)}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{ maxWidth: "400px", margin: "0 auto", width: "100%" }}
      >
        <DialogTitle>Approve Ad Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a category for this ad account
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Categories</InputLabel>
            {categories && (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                onChange={(e) => setCategory(e.target.value)}
              >
                {categories.map((category) => {
                  return (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </FormControl>
          {isShowFormOfPlatform && (
            <>
              <FormControl fullWidth>
                <InputLabel id="currency-label">Currency</InputLabel>
                <Select
                  labelId="currency-label"
                  id="currency"
                  value={currency}
                  label="Currency"
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  {PLATFORM_CURRENCIES[platformId].map((currency) => {
                    return (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="bm-label">Bm ID</InputLabel>
                <Select
                  labelId="bm-label"
                  id="bm"
                  value={bmID}
                  label="Bm ID"
                  onChange={(e) => setBmID(e.target.value)}
                >
                  {bmIDs.map((bm) => {
                    return (
                      <MenuItem key={bm.id} value={bm}>
                        {bm.bm_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {platformId === PLATFORM.META && (
                <FormControl fullWidth>
                  <InputLabel id="bm-label">Credit Line</InputLabel>
                  <Select
                    labelId="bm-label"
                    id="id"
                    value={creditAccount}
                    label="Credit Line"
                    onChange={(e) => setCreditAccount(e.target.value)}
                  >
                    {creditAccounts
                      .filter((item) => item?.platform_settings_id === bmID?.id)
                      .map((creditAccount) => {
                        return (
                          <MenuItem
                            key={creditAccount.id}
                            value={creditAccount}
                          >
                            {creditAccount.credit_line_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )}

              <FormControl fullWidth>
                <Autocomplete
                  id="timezone"
                  options={googleTimezones}
                  getOptionLabel={(option) => option.label || option.name}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Timezone"
                        value={timezoneInput}
                        onInput={(e) => setTimezoneInput(e.target.value)}
                      />
                    );
                  }}
                  value={timezone}
                  onChange={(_e, newValue) => {
                    setTimezone(newValue);
                  }}
                  inputValue={timezoneInput}
                  onInputChange={(_e, newInputValue) =>
                    setTimezoneInput(newInputValue)
                  }
                />
              </FormControl>
              {+platformId === PLATFORM.TIKTOK && (
                <>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="industry"
                      options={industries}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Industry"
                            value={industryInput}
                            onInput={(e) => setIndustryInput(e.target.value)}
                          />
                        );
                      }}
                      value={industry}
                      onChange={(_e, newValue) => setIndustry(newValue)}
                      inputValue={industryInput}
                      onInputChange={(_e, newInputValue) =>
                        setIndustryInput(newInputValue)
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="country-label">Country Code</InputLabel>
                    <Select
                      labelId="country-label"
                      id="country"
                      value={countryCode}
                      label="Country Code"
                      onChange={(e) => setCountryCode(e.target.value)}
                    >
                      {countries.map((country) => {
                        return (
                          <MenuItem key={country.alpha2} value={country.alpha2}>
                            {country.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {bmID?.is_agency && (
                    <FormControl fullWidth>
                      <TextField
                        id="name"
                        label="Platform Url"
                        type="text"
                        fullWidth
                        value={platformUrl}
                        required
                        onChange={(e) => setPlatformUrl(e.target.value)}
                      />
                    </FormControl>
                  )}
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={() => setOpen(false)} />
          <Button
            label="Approve"
            disabled={!categories || loading}
            onClick={() => {
              handleConfirm(
                category,
                currency,
                bmID,
                timezone,
                industry,
                countryCode,
                creditAccount,
                platformUrl
              );
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
